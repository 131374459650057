<template>
  <layout>
    <div id="footerBackground" />
    <div id="headerBackground" />
    <div class="h-screen relative">
      <div class="video-container">
        <video
          autoplay
          muted
          loop
          playsinline
          ref="player">
          <source
            :src="videoSrc"
            type="video/mp4">
        </video>
      </div>
    </div>
    <div class="absolute z-10 w-full top-0 text-white pt-8 pb-20">
      <div class="container mx-auto md:pt-20">
        <div class="mx-auto w-24 md:w-40 mb-8">
          <img
            :src="asset('images/mm-owners-club.png')"
            alt="Micromilspec Owners Club Logo">
        </div>

        <div class="mb-8 text-center">
          <p class="text-xs sm:text-sm uppercase text-yellow-mm-600">
            Micromilspec collectors & owners club
          </p>
          <h1 class="text-3xl sm:text-5xl uppercase font-bold tracking-widest my-2 sm:my-0">
            Become a vip
          </h1>
          <p class="md:text-lg">
            For access to our most exclusive projects. <br>
            Only available to MM owners and collectors.
          </p>
        </div>

        <div
          class="bg-green-700 text-white px-3 py-2 rounded-sm relative text-sm my-3 max-w-md mx-auto text-center"
          role="alert"
          v-if="$page.props.flash.success">
          {{ $page.props.flash.success }}
        </div>

        <div
          class="bg-red-700 text-white px-3 py-2 rounded-sm relative text-sm my-3 max-w-md mx-auto text-center"
          role="alert"
          v-else-if="$page.props.flash.error">
          {{ $page.props.flash.error }}
        </div>

        <form
          v-else
          @submit.prevent="submit">
          <div class="max-w-md mx-auto">
            <input-field
              placeholder="Email"
              class="flex-grow mb-2"
              v-model="formData.email" />
            <radio-list
              :options="['owner', 'collector']"
              v-model="formData.type"
              class="grid grid-cols-2 mb-2">
              <template slot-scope="{ item }">
                <div class="py-2 flex items-center text-sm sm:text-base">
                  <span
                    class="radio-icon mr-3"
                    :class="{ 'radio-icon--checked': formData.type === item }" />
                  <span class="capitalize">{{ item }}</span>
                </div>
              </template>
            </radio-list>

            <div v-if="formData.type == 'owner'">
              <input-field
                placeholder="Which project"
                class="flex-grow mb-4"
                v-model="formData.project" />
            </div>
            <div v-else>
              <div v-if="!formData.image">
                <image-upload
                  class="my-2"
                  @uploaded="setImage" />
              </div>
              <div
                v-else
                class="p-3 border border-gray-mm-500 rounded-sm mb-4">
                {{ imageFilename }} was attached.
              </div>
              <textarea-field
                placeholder="Describe your collection"
                class="flex-grow mb-4"
                label="Upload a photo and write a short summary of your collection. This will help us review your application. We assess each membership application on an individual basis."
                v-model="formData.message" />
            </div>

            <div class="my-6 text-sm">
              <p>* Everyone is welcome to apply as a collector, only owners are guaranteed to be accepted to the list.</p>
            </div>

            <p class="my-6 text-sm">
              <input-checkbox v-model="formData.privacy_terms">
                I agree to let Micromilspec process my personal data in accordance with Micromilspec's <a
                  :href="route('checkout.privacy-policy')"
                  target="_blank"
                  class="underline">Privacy Policy</a>.
              </input-checkbox>
            </p>

            <validation-errors class="my-4" />

            <default-button
              :loading="submitting"
              class="text-base rounded-sm w-full mx-auto block"
              tertiary>
              Sign me up
            </default-button>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"
import RadioList from "@/Shared/RadioList.vue"

export default {
  components: {
    Layout,
    RadioList
  },

  created () {
    try {
      window.fathom("trackPageview")
    } catch (e) {}
  },

  data () {
    return {
      formData: {
        email: null,
        type: "owner",
        message: null,
        project: null,
        image: null,
        privacy_terms: false
      },
      imageFilename: null,
      submitting: false,
      errors: null,
      success: null
    }
  },

  computed: {
    videoSrc () {
      let width = window.innerWidth || screen.width
      if (width > 800 ) {
        return "https://micromilspec-assets.s3.eu-north-1.amazonaws.com/vip-video.mp4"
      }
      return "https://micromilspec-assets.s3.eu-north-1.amazonaws.com/vip-video-mobile.mp4"
    }
  },

  methods: {
    setImage (data) {
      console.log("image data", data)
      this.formData.image = data.uuid
      this.imageFilename = data.name
    },

    submit () {
      this.submitting = true
      this.errors = null
      this.$inertia.post("/", this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(response => {
          this.success = response.data.success
          this.formData.email = null
          this.formData.message = null
          this.formData.project = null
          this.formData.image = null
          this.submitting = false
        })
        .catch(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped>
#headerBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: #000;
}
#footerBackground {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 50%;
  background-color: #000;
}
</style>
